<div class="atlas-menu-container">
  <button
    mat-icon-button
    color="primary"
    aria-label="Main menu"
    [matMenuTriggerFor]="menu"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <button
      matPrefix
      mat-menu-item
      [matMenuTriggerFor]="exportToFormat"
      [disabled]="!reportingItem"
    >
      <mat-icon>arrow_right_alt</mat-icon>
      <span>Export</span>
    </button>
  </mat-menu>

  <mat-menu #exportToFormat="matMenu">
    <button mat-menu-item (click)="exportTo(fileFormat.PDF)">
      <mat-icon>picture_as_pdf</mat-icon>
      <span>PDF</span>
    </button>
    <button mat-menu-item (click)="exportTo(fileFormat.PPTX)">
      <mat-icon>slideshow</mat-icon>
      <span>Power Point</span>
    </button>

    <!-- Support has to activate this option on Power Bi platform at tenant level
    https://community.powerbi.com/t5/Developer/quot-Export-report-to-image-is-disabled-on-tenant-level-quot/m-p/1187677 -->
    <!-- <button mat-menu-item (click)="exportTo(fileFormat.PNG)">
      <mat-icon>image</mat-icon>
      <span>Image</span>
    </button> -->
  </mat-menu>
</div>
