export function isMobileDevice(): boolean {
  const regexs = [
    /(Android)(.+)(Mobile)/i,
    /iPhone/i,
    /Opera Mini/i,
    /IEMobile/i
  ];
  return regexs.some((b) => navigator.userAgent.match(b));
}

export function isTabletDevice(): boolean {
  const regex =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
  return regex.test(navigator.userAgent.toLowerCase());
}

export function isDesktopDevice(): boolean {
  return !isMobileDevice() && !isTabletDevice();
}
