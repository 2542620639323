import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Theme } from '../enums/theme.enum';
import { LocalStorageService } from './local-storage.service';
import { DomainService } from './domain.service';
import { Domain } from '../enums/domain.enum';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  sufix = '-override';

  appTheme$ = new BehaviorSubject<Theme>(this.getDomainTheme());

  appThemeUrl$: Observable<SafeResourceUrl>;
  appOverrideThemeUrl$: Observable<any>;

  constructor(
    public sanitizer: DomSanitizer,
    private localStorageService: LocalStorageService,
    private domainService: DomainService
  ) {
    domainService.setCustomFavIconAndTitle();
    this.appThemeUrl$ = this.appTheme$.pipe(
      map((theme) =>
        this.sanitizer.bypassSecurityTrustResourceUrl(`${theme}.css`)
      )
    );
    this.appOverrideThemeUrl$ = this.appTheme$.pipe(
      map((theme) => {
        return this.sanitizer.bypassSecurityTrustResourceUrl(
          `${theme}${this.sufix}.css`
        );
      })
    );
  }

  swapTheme() {
    const currentTheme = this.getLocalStorageThemeName();
    const newTheme = this.getNewTheme(currentTheme);

    if (this.localStorageService.set('report-viewer-theme', newTheme)) {
      this.appTheme$.next(newTheme);
    }
  }

  private getNewTheme(currentValue: Theme): Theme {
    const newThemeValues = {
      'dark-theme': Theme.Light,
      'light-theme': Theme.Dark,
      'pinroutes-theme': Theme.Pinroutes,
      default: Theme.Dark
    };
    return newThemeValues[currentValue] || newThemeValues['default'];
  }

  private getDomainTheme(): Theme {
    return this.domainService.getDomain() === Domain.Pinroutes
      ? Theme.Pinroutes
      : this.getLocalStorageThemeName();
  }

  private getLocalStorageThemeName(): Theme {
    return (
      (this.localStorageService.get('report-viewer-theme') as Theme) ??
      Theme.Light
    );
  }
}
