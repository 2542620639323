<div class="main-container">
  <div
    class="page-selector-container"
    *ngIf="isMobileDevice && pages.length > 1"
  >
    <div style="margin-bottom: 10px">Select report page:</div>
    <mat-form-field appearance="outline">
      <mat-label>Current report page</mat-label>
      <mat-select
        formControlName="sort"
        (selectionChange)="onPageSelected($event.value)"
        [(value)]="selectedPageName"
        [disableOptionCentering]="true"
      >
        <mat-option *ngFor="let page of pages" [value]="page.name">
          {{ page.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="report-container" *ngIf="isValidToken">
    <powerbi-report
      [embedConfig]="reportConfig"
      cssClassName="report-content"
      [phasedEmbedding]="phasedEmbeddingFlag"
      [eventHandlers]="eventHandlersMap"
    >
    </powerbi-report>
  </div>
  <div
    style="margin: 10px"
    *ngIf="exportReportService.isExportingInProgress$ | async"
  >
    <span class="exporting-status"
      >Exporting report. Completed
      {{ exportReportService.percentageCompleted$ | async }}%</span
    >
    <mat-spinner class="update-button-spinner" diameter="24" strokeWidth="2">
    </mat-spinner>
  </div>
  <div class="no-dashboard" *ngIf="!isValidToken">
    <span> Sorry, there is no Power BI report to show</span>
  </div>
</div>
