import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Domain } from '../enums/domain.enum';
import {
  insiteCustomAttributes,
  pinRoutesCustomAttributes
} from '../constants/domain.constants';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DomainService {
  private readonly domain: Domain;

  private readonly domainBaseUrl: string;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.domain = this.isPinroutesHost() ? Domain.Pinroutes : Domain.Insite;
    this.domainBaseUrl = environment.baseUrl[this.domain];
  }

  public getDomainBaseUrl() {
    return this.domainBaseUrl;
  }

  public getDomain() {
    return this.domain;
  }

  public getAppName() {
    return this.domain === Domain.Pinroutes
      ? pinRoutesCustomAttributes.appName
      : insiteCustomAttributes.appName;
  }

  public setCustomFavIconAndTitle() {
    var favIcon = this.document.getElementById('appFavicon') as HTMLLinkElement;
    const customiseFavIconAndTitle = {
      pinroutes: () => {
        this.setPinRoutesCustomFavIconAndTitle(favIcon);
      },
      insite: () => {
        this.setInsiteCustomFavIconAndTitle(favIcon);
      }
    };

    customiseFavIconAndTitle[this.domain]();
  }

  private setPinRoutesCustomFavIconAndTitle(favIcon: HTMLLinkElement) {
    favIcon.href = pinRoutesCustomAttributes.favIcon;
    this.document.title = pinRoutesCustomAttributes.documentTitle;
  }

  private isPinroutesHost(): boolean {
    return pinRoutesCustomAttributes.domain.some((host) =>
      window.location.host.includes(host)
    );
  }

  private setInsiteCustomFavIconAndTitle(favIcon: HTMLLinkElement) {
    favIcon.href = insiteCustomAttributes.favIcon;
    this.document.title = insiteCustomAttributes.documentTitle;
  }
}
