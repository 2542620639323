import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import * as JSZip from 'jszip';
import { Subscription } from 'rxjs';
import { FileFormat } from 'src/app/power-bi-report-viewer/models/formats-to-export';
import { ReportingItem } from 'src/app/power-bi-report-viewer/models/reporting-item';
import { ExportDashboardService } from 'src/app/power-bi-report-viewer/services/export-dashboard.service';
import { ExportReportService } from 'src/app/power-bi-report-viewer/services/export-report.service';
import { ReportViewerService } from 'src/app/power-bi-report-viewer/services/report-viewer.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'report-viewer-menu',
  templateUrl: './report-viewer-menu.component.html',
  styleUrls: ['./report-viewer-menu.component.less']
})
export class ReportViewerMenuComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  fileFormat = FileFormat;
  selectedFormat: FileFormat | undefined;
  reportingItem: ReportingItem | undefined;

  constructor(
    public reportViewerService: ReportViewerService,
    public exportReportService: ExportReportService,
    public exportDashboardService: ExportDashboardService,
    public snackBarService: SnackbarService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.reportViewerService.reportingItem$.subscribe((currentItem) => {
        this.reportingItem = currentItem;
        this.selectedFormat = undefined;
      })
    );
    this.subscription.add(
      this.exportReportService.isExportingInProgress$.subscribe(
        (exportInProgress) => {
          if (exportInProgress) {
            this.snackBarService.show(
              `Exporting report to ${
                FileFormat[this.selectedFormat!]
              } format. Please wait.`
            );
          }
        }
      )
    );

    this.subscription.add(
      this.exportDashboardService.isExportingInProgress$.subscribe(
        (exportInProgress) => {
          if (exportInProgress) {
            this.snackBarService.show(
              `Exporting dashboard to ${
                FileFormat[this.selectedFormat!]
              } format. Please wait.`
            );
          }
        }
      )
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  exportTo(fileFormat: FileFormat) {
    this.selectedFormat = fileFormat;

    this.reportingItem?.type === 'report'
      ? this.exportReport(fileFormat)
      : this.exportDashboard(fileFormat);
  }

  private exportReport(fileFormat: FileFormat) {
    this.exportReportService.setIsExportingInProgress(true);

    this.exportReportService.exportReport(fileFormat).subscribe({
      next: (response: HttpResponse<Blob>) => {
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(response.body as Blob);
        downloadLink.setAttribute(
          'download',
          'exportedPowerBiReport.' + FileFormat[fileFormat]
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
      error: (e) => {
        this.snackBarService.error('Export Report', e.message);
        this.exportReportService.setIsExportingInProgress(false);
      },
      complete: () => {
        this.exportReportService.setIsExportingInProgress(false);
        this.snackBarService.show(
          `Export report to ${FileFormat[this.selectedFormat!]} finished`
        );
      }
    });
  }

  private exportDashboard(fileFormat: FileFormat) {
    this.exportDashboardService.setIsExportingInProgress(true);

    this.exportDashboardService.exportDashboard(fileFormat).subscribe({
      next: (blobCollection: HttpResponse<Blob>[]) => {
        const zip = new JSZip();
        // Adding the Blobs from the back-end to the zip object
        blobCollection.forEach(
          (exportedTile: HttpResponse<Blob>, index: number) => {
            zip.file(
              `Tile_${index + 1}.${FileFormat[fileFormat].toLowerCase()}`,
              exportedTile.body as Blob
            );
          }
        );
        // Generating the zip file
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          // download the generated zip file to the user's machine
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(content);
          downloadLink.setAttribute('download', 'PowerBiDashboard.zip');
          document.body.appendChild(downloadLink);
          downloadLink.click();
        });
      },
      error: (e) => {
        this.snackBarService.error('Export Dashboard', e.message);
        this.exportDashboardService.setIsExportingInProgress(false);
      },
      complete: () => {
        this.exportDashboardService.setIsExportingInProgress(false);
        this.snackBarService.show(
          `Export dashboard to ${FileFormat[this.selectedFormat!]} finished`
        );
      }
    });
  }
}
