import { Component } from '@angular/core';
import { DomainService } from '../core/services/domain.service';
@Component({
  selector: 'app-home-workspace',
  templateUrl: './home-workspace.component.html',
  styleUrls: ['./home-workspace.component.less']
})
export class HomeWorkspaceComponent {
  constructor(public domainService: DomainService) {}
}
