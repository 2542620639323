<mat-toolbar>
  @if(!(disableExport$ | async)) {
  <report-viewer-menu></report-viewer-menu>
  } @else {
  <button
    mat-icon-button
    (click)="printReport()"
    class="print-button"
    matTooltip="Print"
  >
    <mat-icon>print</mat-icon>
  </button>
  }
  <span class="middle-spacer"></span>
  <span class="insite-logo">
    <!-- The insite-image class is defined in both dark and light theme overrides to use
    different images for the insite logo-->
    <span class="insite-image"></span>
  </span>
</mat-toolbar>
