import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MaterialComponentsModule } from './shared/material/material-components.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HomeWorkspaceComponent } from './home-workspace/home-workspace.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { PowerBiReportViewerComponent } from './power-bi-report-viewer/components/power-bi-report-viewer/power-bi-report-viewer.component';
import { PowerBiDashboardViewerComponent } from './power-bi-report-viewer/components/power-bi-dashboard-viewer/power-bi-dashboard-viewer.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeWorkspaceComponent,
    PowerBiReportViewerComponent,
    PowerBiDashboardViewerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialComponentsModule,
    PowerBIEmbedModule,
    CoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
