import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { ReportingConfigResponse } from '../models/reporting-config-response';
import { ReportingItem } from '../models/reporting-item';
import { DomainService } from 'src/app/core/services/domain.service';

@Injectable({
  providedIn: 'root'
})
export class ReportViewerService {
  private reportingItemSubject$ = new BehaviorSubject<
    ReportingItem | undefined
  >(undefined);
  reportingItem$ = this.reportingItemSubject$.asObservable();

  sharedToken: string = '';

  reportEmbedConfigUrl: string = `${this.domainService.getDomainBaseUrl()}api/report-embed-config`;
  dashboardEmbedConfigUrl: string = `${this.domainService.getDomainBaseUrl()}api/dashboard-embed-config`;

  constructor(
    private httpClient: HttpClient,
    private domainService: DomainService
  ) {}

  setReportingItem(id: string, type: 'dashboard' | 'report') {
    this.reportingItemSubject$.next({ id, type });
  }

  getReportEmbedConfig(token: string): Observable<ReportingConfigResponse> {
    this.sharedToken = token;
    const tokenParam = new HttpParams().append('token', token);
    return this.httpClient
      .get<ReportingConfigResponse>(`${this.reportEmbedConfigUrl}`, {
        params: tokenParam
      })
      .pipe(catchError(this.handleError));
  }

  getDashboardEmbedConfig(token: string): Observable<ReportingConfigResponse> {
    this.sharedToken = token;
    const tokenParam = new HttpParams().append('token', token);
    return this.httpClient
      .get<ReportingConfigResponse>(`${this.dashboardEmbedConfigUrl}`, {
        params: tokenParam
      })
      .pipe(catchError(this.handleError));
  }

  // getDashboardExportedFiles(formatFile: number): Observable<ArrayBuffer> {
  //   return this.httpClient
  //     .get(`${this.exportReportingtUrl}/dashboard/${formatFile}`, {
  //       params: this.tokenParam,
  //       observe: 'body',
  //       responseType: 'arraybuffer'
  //     })
  //     .pipe(catchError(this.handleError));
  // }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Server response code= ' + error.status));
  }
}
