import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  concatMap,
  filter,
  forkJoin,
  map,
  Observable,
  switchMap,
  takeWhile,
  throwError,
  timer,
  zip
} from 'rxjs';
import { ExportReportInfo } from '../models/export-report-info';
import { ExportStates } from '../models/export-states';
import { ReportViewerService } from './report-viewer.service';
import { DomainService } from 'src/app/core/services/domain.service';

@Injectable({
  providedIn: 'root'
})
export class ExportDashboardService {
  private isExportingInProgress: boolean = false;
  private isExportingInProgressSubject$ = new BehaviorSubject<boolean>(false);
  isExportingInProgress$ = this.isExportingInProgressSubject$.asObservable();

  statusPollDelay = 5 * 1000;

  exportDashboardReportUrl: string = `${this.domainService.getDomainBaseUrl()}api/dashboard/reports`;

  constructor(
    private httpClient: HttpClient,
    private reportViewerService: ReportViewerService,
    private domainService: DomainService
  ) {}

  setIsExportingInProgress(isInProgress: boolean) {
    this.isExportingInProgress = isInProgress;
    this.isExportingInProgressSubject$.next(this.isExportingInProgress);
  }

  exportDashboard(fileFormat: number) {
    return this.getReportIdsFromDashboard(
      this.reportViewerService.sharedToken
    ).pipe(
      switchMap((reportIds: string[]) => {
        const exportTileRequets = reportIds.map((reportId) =>
          this.exportDashboardTile(reportId, fileFormat)
        );
        return forkJoin(exportTileRequets);
      })
    );
  }

  private exportDashboardTile(reportId: string, fileFormat: number) {
    return this.createExportDashboardReport(
      reportId,
      this.reportViewerService.sharedToken,
      fileFormat
    ).pipe(
      concatMap((exportInfo: ExportReportInfo) => {
        return this.pollForReportExportStatus(
          this.reportViewerService.sharedToken,
          reportId,
          exportInfo
        ).pipe(
          switchMap((exportInfo: ExportReportInfo) => {
            return this.getExportedReportFile(
              this.reportViewerService.sharedToken,
              reportId,
              exportInfo.id
            );
          })
        );
      })
    );
  }

  private createExportDashboardReport(
    reportId: string,
    token: string,
    fileFormat: number
  ): Observable<ExportReportInfo> {
    const tokenParam = new HttpParams().append('token', token);
    return this.httpClient
      .get<ExportReportInfo>(
        `${this.exportDashboardReportUrl}/${reportId}/exports/${fileFormat}`,
        {
          params: tokenParam
        }
      )
      .pipe(catchError(this.handleError));
  }

  public pollForReportExportStatus(
    token: string,
    reportId: string,
    exportReportInfo: ExportReportInfo
  ) {
    return this.pollForProgressUntilComplete(token, reportId, exportReportInfo)
      .pipe(
        map((exportReportInfo: ExportReportInfo) => {
          if (
            exportReportInfo.status === ExportStates.Failed ||
            exportReportInfo.status === ExportStates.Undefined
          ) {
            throw new Error(`Export failed. Unknown error, please try again.`);
          }
          return exportReportInfo;
        }),
        filter(
          (exportReportInfo: ExportReportInfo) =>
            exportReportInfo.status === ExportStates.Succeeded
        )
      )
      .pipe(catchError(this.handleError));
  }

  private pollForProgressUntilComplete(
    token: string,
    reportId: string,
    exportReportInfo: ExportReportInfo
  ) {
    return timer(0, this.statusPollDelay)
      .pipe(
        switchMap(() => {
          return this.getExportReportInfoStatus(
            token,
            reportId,
            exportReportInfo
          );
        }),
        takeWhile(
          (exportReportInfo: ExportReportInfo) =>
            (exportReportInfo.status === ExportStates.Running ||
              exportReportInfo.status === ExportStates.NotStarted) &&
            this.isExportingInProgress,
          true
        )
      )
      .pipe(catchError(this.handleError));
  }

  private getExportReportInfoStatus(
    token: string,
    reportId: string,
    exportReportInfo: ExportReportInfo
  ) {
    const tokenParam = new HttpParams().append('token', token);
    return this.httpClient
      .get<ExportReportInfo>(
        `${this.exportDashboardReportUrl}/${reportId}/exports/${exportReportInfo.id}/status`,
        {
          params: tokenParam
        }
      )
      .pipe(catchError(this.handleError));
  }

  public getExportedReportFile(
    token: string,
    reportId: string,
    exportId: string
  ): Observable<HttpResponse<Blob>> {
    const tokenParam = new HttpParams().append('token', token);
    return this.httpClient
      .get(
        `${this.exportDashboardReportUrl}/${reportId}/exports/${exportId}/report-file`,
        {
          params: tokenParam,
          responseType: 'blob',
          observe: 'response'
        }
      )
      .pipe(catchError(this.handleError));
  }

  private getReportIdsFromDashboard(token: string): Observable<string[]> {
    const tokenParam = new HttpParams().append('token', token);
    return this.httpClient
      .get<string[]>(`${this.exportDashboardReportUrl}`, {
        params: tokenParam
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error('Export failed. Unknown error, please try again.')
    );
  }
}
