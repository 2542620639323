export const pinRoutesCustomAttributes = {
  domain: ['localhost:4201', 'pinroutes'],
  documentTitle: 'Pin Routes Dashboard',
  favIcon: '/assets/logos/faviconpr.ico',
  appName: 'Pin Routes'
};

export const insiteCustomAttributes = {
  domain: ['localhost:4200', 'insite'],
  documentTitle: 'Insite Report',
  favIcon: 'favicon.ico',
  appName: 'Insite Everywhere'
};
