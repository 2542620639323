import { ExportReportService } from 'src/app/power-bi-report-viewer/services/export-report.service';
import { Observable } from 'rxjs';
import { Component } from '@angular/core';

@Component({
  selector: 'report-viewer-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less']
})
export class NavbarComponent {
  disableExport$: Observable<boolean> =
    this.exportReportService.isReportExportDisabled();

  constructor(private exportReportService: ExportReportService) {}

  printReport(): void {
    window.print();
  }
}
