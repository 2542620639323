import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WithNavbarComponent } from './core/components/with-navbar/with-navbar.component';
import { HomeWorkspaceComponent } from './home-workspace/home-workspace.component';
import { PowerBiDashboardViewerComponent } from './power-bi-report-viewer/components/power-bi-dashboard-viewer/power-bi-dashboard-viewer.component';
import { PowerBiReportViewerComponent } from './power-bi-report-viewer/components/power-bi-report-viewer/power-bi-report-viewer.component';
const routes: Routes = [
  {
    path: '',
    component: WithNavbarComponent,
    children: [
      {
        path: '',
        component: HomeWorkspaceComponent,
      },
      {
        path: 'report-viewer',
        component: PowerBiReportViewerComponent,
      },
      {
        path: 'dashboard-viewer',
        component: PowerBiDashboardViewerComponent,
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
