import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MaterialComponentsModule } from 'src/app/shared/material/material-components.module';
import * as fromComponents from './components';

@NgModule({
  imports: [CommonModule, RouterModule, MaterialComponentsModule],
  declarations: [...fromComponents.components],
  exports: [...fromComponents.components],
})
export class CoreModule {}
