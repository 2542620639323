import { Component, OnInit, ViewChild } from '@angular/core';
import { PowerBIDashboardEmbedComponent } from 'powerbi-client-angular';
import { IDashboardEmbedConfiguration, models, service } from 'powerbi-client';
import { ReportViewerService } from '../../services/report-viewer.service';

import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FileFormat } from '../../models/formats-to-export';
import { catchError, EMPTY, iif, switchMap, throwError } from 'rxjs';
import { ReportingConfigResponse } from '../../models/reporting-config-response';
import { ExportDashboardService } from '../../services/export-dashboard.service';
@Component({
  selector: 'app-power-bi-dashboard-viewer',
  templateUrl: './power-bi-dashboard-viewer.component.html',
  styleUrls: ['./power-bi-dashboard-viewer.component.less']
})
export class PowerBiDashboardViewerComponent implements OnInit {
  // Wrapper object to access report properties
  @ViewChild(PowerBIDashboardEmbedComponent)
  dashboardObj!: PowerBIDashboardEmbedComponent;
  isValidToken: boolean = false;
  reportStatus: string = '';

  public get fileFormat(): typeof FileFormat {
    return FileFormat;
  }

  // Pass the basic embed configurations to the wrapper to bootstrap the report on first load
  // Values for properties like embedUrl, accessToken and settings will be set on click of button
  dashboardConfig: IDashboardEmbedConfiguration = {
    type: 'dashboard',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false
        }
      }
    },
    pageView: 'fitToWidth'
  };

  phasedEmbeddingFlag = false;

  eventHandlersMap = new Map<
    string,
    (event?: service.ICustomEvent<any>) => void
  >([
    ['loaded', () => (this.reportStatus = 'Dashboard successfully loaded')],
    ['rendered', () => (this.reportStatus = 'Dashboard successfully rendered')],
    [
      'error',
      (event?: service.ICustomEvent<any>) => {
        if (event) {
          this.reportStatus = event.detail;
        }
      }
    ]
  ]);

  constructor(
    private router: Router,
    private activatedroute: ActivatedRoute,
    public powerBiReportViewerService: ReportViewerService,
    public exportDashboardService: ExportDashboardService
  ) {}

  ngOnInit() {
    this.activatedroute.queryParamMap
      .pipe(
        switchMap((params: ParamMap) => {
          const queryToken = params.get('token');
          this.isValidToken = queryToken != null;
          return iif(
            () => queryToken != null,
            this.powerBiReportViewerService.getDashboardEmbedConfig(
              queryToken!
            ),
            EMPTY
          );
        }),
        catchError((err) => {
          this.isValidToken = false;
          return throwError(() => new Error(err));
        })
      )
      .subscribe((reportingConfigResponse: ReportingConfigResponse) => {
        this.powerBiReportViewerService.setReportingItem(
          reportingConfigResponse.id,
          'dashboard'
        );
        this.dashboardConfig = {
          ...this.dashboardConfig,
          id: reportingConfigResponse.id,
          embedUrl: reportingConfigResponse.embedUrl,
          accessToken: reportingConfigResponse.embedToken,
          type: 'dashboard'
        };
      });
  }

  goToHome() {
    this.router.navigate(['']);
  }
}
