import { Component, OnInit, ViewChild } from '@angular/core';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import {
  IReportEmbedConfiguration,
  models,
  service,
  Report,
  Page
} from 'powerbi-client';
import { ReportViewerService } from '../../services/report-viewer.service';

import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FileFormat } from '../../models/formats-to-export';
import { ReportingConfigResponse } from '../../models/reporting-config-response';
import { catchError, EMPTY, iif, switchMap, throwError } from 'rxjs';
import { ExportReportService } from '../../services/export-report.service';
import { isMobileDevice } from '../../utils/device-detection.utils';

@Component({
  selector: 'app-power-bi-report-viewer',
  templateUrl: './power-bi-report-viewer.component.html',
  styleUrls: ['./power-bi-report-viewer.component.less']
})
export class PowerBiReportViewerComponent implements OnInit {
  // Wrapper object to access report properties
  @ViewChild(PowerBIReportEmbedComponent)
  reportObj!: PowerBIReportEmbedComponent;

  isValidToken = false;
  reportStatus = '';
  report: Report | null = null;
  pages: Page[] = [];
  selectedPageName: string = '';
  isMobileDevice = isMobileDevice();

  public get fileFormat(): typeof FileFormat {
    return FileFormat;
  }
  // Pass the basic embed configurations to the wrapper to bootstrap the report on first load
  // Values for properties like embedUrl, accessToken and settings will be set on click of button
  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    id: undefined,
    embedUrl: undefined,
    accessToken: undefined,
    tokenType: models.TokenType.Embed,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false
        }
      },
      layoutType: models.LayoutType.MobilePortrait
    }
  };

  phasedEmbeddingFlag = false;

  eventHandlersMap = new Map<
    string,
    (event?: service.ICustomEvent<any>) => void
  >([
    [
      'loaded',
      async () => {
        this.reportStatus = 'Report successfully rendered';
        this.report = this.reportObj.getReport();
        this.pages = (await this.report.getPages()).filter(
          (page) => page.visibility === 0 /*AlwaysVisible*/
        );
        this.selectedPageName = (await this.report.getActivePage()).name;
      }
    ],
    ['rendered', () => (this.reportStatus = 'Report successfully rendered')],
    [
      'error',
      (event?: service.ICustomEvent<any>) => {
        if (event) {
          this.reportStatus = event.detail;
        }
      }
    ]
  ]);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public powerBiReportViewerService: ReportViewerService,
    public exportReportService: ExportReportService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParamMap
      .pipe(
        switchMap((params: ParamMap) => {
          const queryToken = params.get('token');
          this.isValidToken = queryToken != null;
          return iif(
            () => queryToken != null,
            this.powerBiReportViewerService.getReportEmbedConfig(queryToken!),
            EMPTY
          );
        }),
        catchError((err) => {
          this.isValidToken = false;
          return throwError(() => new Error(err));
        })
      )
      .subscribe((reportingConfigResponse: ReportingConfigResponse) => {
        this.powerBiReportViewerService.setReportingItem(
          reportingConfigResponse.id,
          'report'
        );

        const settingsByDevice = this.getDeviceSettings();
        this.reportConfig = {
          ...this.reportConfig,
          id: reportingConfigResponse.id,
          embedUrl: reportingConfigResponse.embedUrl,
          accessToken: reportingConfigResponse.embedToken,
          type: 'report',
          settings: settingsByDevice
        };
      });
  }

  goToHome() {
    this.router.navigate(['']);
  }

  async onPageSelected(pageName: string) {
    await this.report!.setPage(pageName);
    this.selectedPageName = (await this.report!.getActivePage()).name;
  }

  private getDeviceSettings() {
    let settings: any;
    settings = this.isMobileDevice
      ? {
          ...this.reportConfig.settings,
          layoutType: models.LayoutType.MobilePortrait
        }
      : {
          ...this.reportConfig.settings,
          layoutType: models.LayoutType.Custom,
          customLayout: { displayOption: models.DisplayOption.FitToPage }
        };
    return settings;
  }
}
