import { Component } from '@angular/core';

@Component({
  selector: 'report-viewer-with-navbar',
  templateUrl: './with-navbar.component.html',
  styleUrls: ['./with-navbar.component.less'],
})
export class WithNavbarComponent {
  constructor() {}
}
