<div class="main-container">
  <div class="dashboard-container" *ngIf="isValidToken">
    <powerbi-dashboard
      [embedConfig]="dashboardConfig"
      cssClassName="dashboard-content"
      [eventHandlers]="eventHandlersMap"
    >
    </powerbi-dashboard>
  </div>
  <div
    style="margin: 10px"
    *ngIf="exportDashboardService.isExportingInProgress$ | async"
  >
    <span class="exporting-status">Exporting dashboard...</span>
    <mat-spinner class="update-button-spinner" diameter="24" strokeWidth="2">
    </mat-spinner>
  </div>
  <div class="no-dashboard" *ngIf="!isValidToken">
    <span> Sorry, there is no Power BI dashboard to show</span>
  </div>
</div>
