import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  concatMap,
  filter,
  map,
  Observable,
  switchMap,
  takeWhile,
  throwError,
  timer
} from 'rxjs';
import { ExportReportInfo } from '../models/export-report-info';
import { ExportStates } from '../models/export-states';
import { ReportViewerService } from './report-viewer.service';
import { DomainService } from 'src/app/core/services/domain.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ExportReportService {
  private isExportingInProgress: boolean = false;
  private isExportingInProgressSubject$ = new BehaviorSubject<boolean>(false);
  isExportingInProgress$ = this.isExportingInProgressSubject$.asObservable();

  private percentageCompletedSubject$ = new BehaviorSubject<number>(0);
  percentageCompleted$ = this.percentageCompletedSubject$.asObservable();

  statusPollDelay = 5 * 1000;

  exportReportUrl: string = `${this.domainService.getDomainBaseUrl()}api/report/exports`;

  constructor(
    private httpClient: HttpClient,
    public reportViewerService: ReportViewerService,
    private domainService: DomainService,
    private activatedRoute: ActivatedRoute
  ) {}

  isReportExportDisabled(): Observable<boolean> {
    const queryToken: string =
      this.activatedRoute.snapshot.queryParamMap.get('token') ?? '';

    const tokenParam = new HttpParams().append('token', queryToken);
    return this.httpClient
      .get<boolean>(`${this.exportReportUrl}/export-available`, {
        params: tokenParam
      })
      .pipe(catchError(this.handleError));
  }

  setIsExportingInProgress(isInProgress: boolean) {
    this.isExportingInProgress = isInProgress;
    this.isExportingInProgressSubject$.next(this.isExportingInProgress);
  }

  exportReport(fileFormat: number): Observable<HttpResponse<Blob>> {
    return this.createExport(this.reportViewerService.sharedToken, fileFormat)
      .pipe(
        concatMap((exportInfo: ExportReportInfo) => {
          return this.pollForReportExportStatus(
            this.reportViewerService.sharedToken,
            exportInfo
          ).pipe(
            switchMap((exportInfo: ExportReportInfo) => {
              return this.getExportedReportFile(
                this.reportViewerService.sharedToken,
                exportInfo.id
              );
            })
          );
        })
      )
      .pipe(catchError(this.handleError));
  }

  private createExport(
    token: string,
    fileFormat: number
  ): Observable<ExportReportInfo> {
    const tokenParam = new HttpParams().append('token', token);
    return this.httpClient
      .get<ExportReportInfo>(`${this.exportReportUrl}/${fileFormat}`, {
        params: tokenParam
      })
      .pipe(catchError(this.handleError));
  }

  private pollForReportExportStatus(
    token: string,
    exportReportInfo: ExportReportInfo
  ) {
    return this.pollForProgressUntilComplete(token, exportReportInfo)
      .pipe(
        map((exportReportInfo: ExportReportInfo) => {
          if (
            exportReportInfo.status === ExportStates.Failed ||
            exportReportInfo.status === ExportStates.Undefined
          ) {
            this.percentageCompletedSubject$.next(0);
            throw new Error(`Export failed. Unknown error, please try again.`);
          }
          this.percentageCompletedSubject$.next(
            exportReportInfo.percentComplete
          );
          return exportReportInfo;
        }),
        filter(
          (exportReportInfo: ExportReportInfo) =>
            exportReportInfo.status === ExportStates.Succeeded
        )
      )
      .pipe(catchError(this.handleError));
  }

  private pollForProgressUntilComplete(
    token: string,
    exportReportInfo: ExportReportInfo
  ) {
    return timer(0, this.statusPollDelay)
      .pipe(
        switchMap(() => {
          return this.getExportReportInfoStatus(token, exportReportInfo);
        }),
        takeWhile(
          (exportReportInfo: ExportReportInfo) =>
            (exportReportInfo.status === ExportStates.Running ||
              exportReportInfo.status === ExportStates.NotStarted) &&
            this.isExportingInProgress,
          true
        )
      )
      .pipe(catchError(this.handleError));
  }

  private getExportReportInfoStatus(
    token: string,
    exportReportInfo: ExportReportInfo
  ) {
    const tokenParam = new HttpParams().append('token', token);
    return this.httpClient
      .get<ExportReportInfo>(
        `${this.exportReportUrl}/${exportReportInfo.id}/status`,
        {
          params: tokenParam
        }
      )
      .pipe(catchError(this.handleError));
  }

  private getExportedReportFile(
    token: string,
    exportId: string
  ): Observable<HttpResponse<Blob>> {
    const tokenParam = new HttpParams().append('token', token);
    return this.httpClient
      .get(`${this.exportReportUrl}/${exportId}/report-file`, {
        params: tokenParam,
        responseType: 'blob',
        observe: 'response'
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error('Export failed. Unknown error, please try again.')
    );
  }
}
